import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 184
  },
  small: {
    width: 152
  }
}));

export default function TimerBetweenSets(props) {
  const classes = useStyles();
  const setCompletedCount = props.setCompletedCount;
  const [timerCompletedCount, setTimerCompletedCount] = React.useState(0);
  const [timerKey, setTimerKey] = React.useState(0);
  
  React.useEffect(() => {
    if (timerCompletedCount < setCompletedCount) {
      setTimerKey(prevTimerKey => prevTimerKey + 1);
    }
  }, [timerCompletedCount, setCompletedCount]);

  const onTimerComplete = () => {
    setTimerCompletedCount((timerCompletedCount) => timerCompletedCount + 1);
    props.onTimerComplete();
  }

  let className = props.className + " " + classes.root;

  if (props.small) {
    className += " " + classes.small;
  }

  const size = props.small ? 152 : 184;

  return (
    <div className={className}>
      <CountdownCircleTimer
        key={timerKey}
        isPlaying={true}
        duration={30}
        onComplete={onTimerComplete}
        size={size}
        colors='#795841'
        trailColor='#F2EBE4'
      >
        {({ remainingTime }) => remainingTime}
      </CountdownCircleTimer>
    </div>
  );
}
