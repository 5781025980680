import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import VerticalLineStepper from './VerticalLineStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import {createUseStyles} from 'react-jss';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';

const useStyles = createUseStyles({
  verticalLineStepper: {
    paddingBottom: 248
  },
  paper: {
  	position: "fixed",
  	bottom: 0,
  	width: "100%",
    filter: 'drop-shadow(0px -4px 8px rgba(87, 90, 140, 0.25))',
    '&.MuiPaper-root': {
      backgroundColor: '#F2EBE4',
    },
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    }
  },
  paperLabel: {
    color: '#26401B',
    '&.MuiTypography-root': {
      marginLeft: 16,
    },
    '&:before': {
      display: 'inline-block',
      content: '""',
      width: 0,
      height: 24,
      verticalAlign: 0
    },
    '&:after': {
      display: 'inline-block',
      content: '""',
      width: 0,
      height: 16,
      verticalAlign: -16
    }
  },
  timer: {
    width: 184,
    margin: 'auto',
    marginBottom: 16
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#EAEBF1'
      },
    },
    MuiStepIcon: {
      root: {
        color: '#C9CCDD',
        '&$active': {
          color: '#322F52',
          '& .MuiStepIcon-text': {
            fill: '#EAEBF1',
          }
        },
        '&$completed': {
          color: '#322F52',
        },
      },
      text: {
        fill: '#322F52',
        fontFamily: 'Dosis'
      }
    },
    MuiStepLabel: {
      label: {
        color: '#868BAD',
        '&$active': {
          color: '#26401B',
          fontWeight: 400
        },
        '&$completed': {
          color: '#26401B'
        },
      }
    },
    MuiStepContent: {
      root: {
        borderLeft: '1px solid #A7ACC5'
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: '#A7ACC5'
      },
    },
    MuiCheckbox: {
      root: {
        color: '#A28A79'
      },
      colorPrimary: {
          color: '#795841',
          '&.Mui-checked': {
            color: '#795841',
          }
      }
    },
    MuiButton: {
      root: {
        color: '#26401B'
      },
      label: {
        fontSize: '1rem',
        fontFamily: 'Dosis'
      }
    }
  },
});

const client = new ApolloClient({
  uri: 'https://workout-back-end-dot-black-moon-309018.uc.r.appspot.com/graphql',
  headers: {
    'content-type': 'text/plain', // Simple request (no pre-flight)
  },
  cache: new InMemoryCache()
});

function App() {
  const [showPaper, setShowPaper] = React.useState(false);

  const onTimerComplete = () => {
    setShowPaper(false);
  }

  const onBlockComplete = () => {
    setShowPaper(true);
  }

  const classes = useStyles();
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <VerticalLineStepper className={classes.verticalLineStepper} onBlockComplete={onBlockComplete}/>
      	  {showPaper &&
      	  	<Paper className={classes.paper}>
      	  		<Typography 
                className={classes.paperLabel}
                variant='body2'>
                Prepare for next block
              </Typography>
              <div className={classes.timer}>
      	        <CountdownCircleTimer
      	          isPlaying={true}
      	          duration={60}
      	          onComplete={onTimerComplete}
      	          colors='#795841'
                  trailColor='#F2EBE4'
                  size={184}
      	        >
      	          {({ remainingTime }) => remainingTime}
      	        </CountdownCircleTimer>
              </div>
              </Paper>
            }
        </div>
      </ThemeProvider>
     </ApolloProvider>
  );
}

export default App;
