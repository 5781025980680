import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LandscapeImage from './LandscapeImage';
import PortraitImage from './PortraitImage';
import chestPressStart from './chest_press_start.svg';
import rotatedChestUp from './rotated_chest_up.svg';
import chestFlyEnd from './chest_fly_end.svg';
import chestPressEnd from './chest_press_end.svg';
import declinePressStart from './decline_press_start.svg';
import declinePressEnd from './decline_press_end.svg';
import tricepsKickbackStart from './triceps_kickback_start.svg';
import tricpesKickbackEnd from './triceps_kickback_end.svg';
import singleSideCrushersStart from './single_side_crushers_start.svg';
import singleSideCrushersEnd from './single_side_crushers_end.svg';
import tricepsPushUpsStart from './triceps_push-ups_start.svg';
import tricepsPushUpsEnd from './triceps_push-ups_end.svg';
import pulloverStart from './pullover_start.svg';
import pulloverEnd from './pullover_end.svg';
import traditionalRowStart from './traditional_row_start.svg';
import traditionalRowEnd from './traditional_row_end.svg';
import wideRowStart from './wide_row_start.svg';
import wideRowEnd from './wide_row_end.svg';
import wideCurlStart from './wide_curl_start.svg';
import wideCurlEnd from './wide_curl_end.svg';
import reverseFlyStart from './reverse_fly_start.svg';
import reverseFlyEnd from './reverse_fly_end.svg';
import topOfCurl from './top_of_curl.svg';
import middleOfCurl from './middle_of_curl.svg';
import bottomOfCurl from './bottom_of_curl.svg';
import standingBackGrip from './standing_back_grip.svg';
import frontRaiseEnd from './front_raise_end.svg';
import shoulderPressStart from './shoulder_press_start.svg';
import shoulderPressEnd from './shoulder_press_end.svg';
import uprightRowEnd from './upright_row_end.svg';
import lateralRaiseStart from './lateral_raise_start.svg';
import lateralRaiseEnd from './lateral_raise_end.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFF',
    filter: 'drop-shadow(0px 2px 2px rgba(87, 90, 140, 0.25))'
  },
  topSection: {
    minHeight: 96,
    display: 'flex'
  },
  startImageContainer: {
    width: 'calc(50% - 20px)',
    marginLeft: 8,
    display: 'flex'
  },
  endImageContainer: {
    width: 'calc(50% - 20px)',
    marginLeft: 24,
    display: 'flex'
  },
  bottomSection: {
    backgroundColor: '#F2EBE4',
  },
  exerciseLabel: {
    fontFamily: 'Dosis',
    fontSize: '1.25rem',
    color: '#40281E',
    marginLeft: 8,
    '&:before': {
      display: 'inline-block',
      content: '""',
      width: 0,
      height: 24,
      verticalAlign: 0
    },
    '&:after': {
      display: 'inline-block',
      content: '""',
      width: 0,
      height: 16,
      verticalAlign: -16
    }
  }
}));

export default function ExerciseCard(props) {
  const classes = useStyles();
  const className = props.className+" "+classes.root;
  let startImage = null;
  let endImage = null;

  if (props.label.toLowerCase().endsWith("chest press")) {
    startImage = (
      <LandscapeImage
        start
        label={props.label}
        src={chestPressStart}/>
    );

    if (props.label === "Rotating chest press") {
      endImage = (
        <LandscapeImage
          end
          label={props.label}
          src={rotatedChestUp}/>
      );
    } else {
      endImage = (
        <LandscapeImage
          end
          label={props.label}
          src={chestPressEnd}/>
      );
    }
  } else if (props.label === "Chest fly") {
    startImage = (
      <LandscapeImage
        start
        label={props.label}
        src={rotatedChestUp}/>
    );
    endImage = (
      <LandscapeImage
        end
        label={props.label}
        src={chestFlyEnd}/>
    );
  } else if (props.label === "Decline press") {
    startImage = (
      <LandscapeImage
        start
        label={props.label}
        src={declinePressStart}/>
    );
    endImage = (
      <LandscapeImage
        end
        label={props.label}
        src={declinePressEnd}/>
    );
  } else if (props.label === "Triceps kickback") {
    startImage = (
      <PortraitImage
        start
        label={props.label}
        src={tricepsKickbackStart}/>
    );
    endImage = (
      <PortraitImage
        end
        label={props.label}
        src={tricpesKickbackEnd}/>
    );
  } else if (props.label === "Single side crushers") {
    startImage = (
      <LandscapeImage
        start
        label={props.label}
        src={singleSideCrushersStart}/>
    );
    endImage = (
      <LandscapeImage
        end
        label={props.label}
        src={singleSideCrushersEnd}/>
    );
  } else if (props.label === "Triceps push-ups") {
    startImage = (
      <LandscapeImage
        start
        label={props.label}
        src={tricepsPushUpsStart}/>
    );
    endImage = (
      <LandscapeImage
        end
        label={props.label}
        src={tricepsPushUpsEnd}/>
    );
  } else if (props.label === "Pullover") {
    startImage = (
      <LandscapeImage
        start
        label={props.label}
        src={pulloverStart}/>
    );
    endImage = (
      <LandscapeImage
        end
        label={props.label}
        src={pulloverEnd}/>
    );
  } else if (props.label === "Traditional row") {
    startImage = (
      <PortraitImage
        start
        label={props.label}
        src={traditionalRowStart}/>
    );
    endImage = (
      <PortraitImage
        end
        label={props.label}
        src={traditionalRowEnd}/>
    );
  }  else if (props.label === "Wide row") {
    startImage = (
      <PortraitImage
        start
        label={props.label}
        src={wideRowStart}/>
    );
    endImage = (
      <PortraitImage
        end
        label={props.label}
        src={wideRowEnd}/>
    );
  } if (props.label === "Wide curl") {
    startImage = (
      <PortraitImage
        start
        label={props.label}
        src={wideCurlStart}/>
    );
    endImage = (
      <PortraitImage
        end
        label={props.label}
        src={wideCurlEnd}/>
    );
  } else if (props.label.toLowerCase().endsWith("curl")) {
    if (props.label.toLowerCase().startsWith("top")) {
      startImage = (
        <PortraitImage
          start
          label={props.label}
          src={middleOfCurl}/>
      );
    } else {
      startImage = (
        <PortraitImage
          start
          label={props.label}
          src={bottomOfCurl}/>
      );
    }

    if (props.label.toLowerCase().startsWith("bottom")) {
      endImage = (
        <PortraitImage
          start
          label={props.label}
          src={middleOfCurl}/>
      );
    } else {
      endImage = (
        <PortraitImage
          start
          label={props.label}
          src={topOfCurl}/>
      );
    }
  } else if (props.label === "Reverse fly") {
    startImage = (
      <PortraitImage
        start
        label={props.label}
        src={reverseFlyStart}/>
    );
    endImage = (
      <PortraitImage
        end
        label={props.label}
        src={reverseFlyEnd}/>
    );
  } else if (props.label === "Front raise") {
    startImage = (
      <PortraitImage
        start
        label={props.label}
        src={standingBackGrip}/>
    );
    endImage = (
      <PortraitImage
        end
        label={props.label}
        src={frontRaiseEnd}/>
    );
  } else if (props.label === "Shoulder press") {
    startImage = (
      <PortraitImage
        start
        label={props.label}
        src={shoulderPressStart}/>
    );
    endImage = (
      <PortraitImage
        end
        label={props.label}
        src={shoulderPressEnd}/>
    );
  } else if (props.label === "Upright row") {
    startImage = (
      <PortraitImage
        start
        label={props.label}
        src={standingBackGrip}/>
    );
    endImage = (
      <PortraitImage
        end
        label={props.label}
        src={uprightRowEnd}/>
    );
  } else if (props.label === "Lateral raise") {
    startImage = (
      <PortraitImage
        start
        label={props.label}
        src={lateralRaiseStart}/>
    );
    endImage = (
      <PortraitImage
        end
        label={props.label}
        src={lateralRaiseEnd}/>
    );
  } 

  return (
    <div className={className}>
      <div className={classes.topSection}>
        <div className={classes.startImageContainer}>
         {startImage &&
           startImage
         }
        </div>
        <div className={classes.endImageContainer}>
         {endImage &&
           endImage
         }
        </div>
      </div>
      <div className={classes.bottomSection}>
        <span className={classes.exerciseLabel}>
          {props.label}
        </span>
      </div>
    </div>
  );
}