import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SetCheckboxes from './SetCheckboxes';
import ExerciseCard from './exercise-card';
import TimerBetweenSets from './TimerBetweenSets';
import CoreTimer from './CoreTimer';

const useStyles = makeStyles((theme) => ({
  exerciseCards: {
    marginLeft: 32,
    marginRight: 32,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  exerciseCard: {
    width: '100%',
    marginTop: 8
  },
  bottomSection: {
    position: 'relative',
    marginTop: 16,
    display: 'flex'
  },
  leftSection: {
    position: 'absolute',
    left: 32
  },
  threeSets: {
    width: 'calc(25% - 6px)',
    marginLeft: 'calc(75% - 12px)'
  },
  timerBetweenSets: {
    margin: 'auto'
  }
}));

export default function CoreStep(props) {
  const classes = useStyles();
  const [setKey, setSetKey] = React.useState(0);
  const [showExerciseTimer, setShowExerciseTimer] = React.useState(true);
  const [setDoneStatus, setSetDoneStatus] = React.useState([false, false, false]);
  const [showTimerBetweenSets, setShowTimerBetweenSets] = React.useState(false);
  const firstUncompleteSetIndex = setDoneStatus.findIndex((setDoneStatus) => !setDoneStatus);

  const onSetComplete = () => {
    setSetKey(prevSetKey => prevSetKey + 1);
    setShowExerciseTimer(false);
    setSetDoneStatus((setDoneStatus) => {
      return setDoneStatus.slice(0, firstUncompleteSetIndex).concat([true].concat(setDoneStatus.slice(firstUncompleteSetIndex + 1)));
    });

    if (firstUncompleteSetIndex !== setDoneStatus.length) {
      setShowTimerBetweenSets(true);
    }
  };

  const onTimerBetweenSetsComplete = () => {
    setShowTimerBetweenSets(false);
    setShowExerciseTimer(true);
  };

  const onSetCheckboxChange = (index, event) => {
    setSetDoneStatus((setDoneStatus) => {
      return setDoneStatus.slice(0, index).concat([event.target.checked].concat(setDoneStatus.slice(index + 1)));
    });
  };

  return (
    <div>
      <div className={classes.exerciseCards}>
        {props.exercises.map((exercise, index) => (
          <ExerciseCard key={index} className={classes.exerciseCard} label={exercise}/>
        ))}
      </div>
      <div className={classes.bottomSection}>
        <div className={classes.leftSection}>
          {showExerciseTimer &&
            <CoreTimer 
              key={setKey}
              onComplete={onSetComplete}/>
          }
          {showTimerBetweenSets &&
            <TimerBetweenSets
              small
              className={classes.timerBetweenSets}
              setCompletedCount={firstUncompleteSetIndex}
              onTimerComplete={onTimerBetweenSetsComplete}
            />
          }
        </div>
        <SetCheckboxes
          className={classes.threeSets}
          vertical
          setDoneStatus={setDoneStatus}
          onComplete={props.onComplete}
          onSetCheckboxChange={onSetCheckboxChange}
          />
      </div>
    </div>
  );
}