import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 8,
    marginBottom: 8
  }
}));

export default function LandscapeImage(props) {
  const classes = useStyles();
  let alt = props.label;

  if (props.start) {
    alt += " start";
  } else if (props.end) {
    alt += " end";
  }

  return (
    <img 
      src={props.src} 
      alt={alt}
      className={classes.root}/>
  );
}