import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { gql, useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import CoreStep from './CoreStep';
import HiitStep from './HiitStep';
import WeightLiftingBlockStep from './WeightLiftingBlockStep';

const RECOMMENDED_NEXT_WORKOUT = gql`
  query RecommendedNextWorkout {
    recommendedNextWorkout {
      id
      weightLiftingBlocks {
        exercises
      }
      hiitExercises
      coreExercises
    }
  }
`;

const RECORD_DOING_WORKOUT = gql`
  mutation RecordDoingWorkout($workoutId: String!, $date: String!) {
    recordDoingWorkout(workoutId: $workoutId, date: $date) {
      id
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepper: {
    padding: 16
  },
  stepContent: {
    paddingLeft: 0,
    paddingRight: 0
  },
  innerStepContent: {
    marginLeft: -12
  }
}));

export default function VerticalLinearStepper(props) {
  const classes = useStyles();
  const date = moment().format("YYYY-MM-DD");
  const { loading, error, data } = useQuery(RECOMMENDED_NEXT_WORKOUT);
  const [ recordDoingWorkout ] = useMutation(RECORD_DOING_WORKOUT);

  const [activeStep, setActiveStep] = React.useState(0);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const hasHiit = data.recommendedNextWorkout.hiitExercises.length > 0;
  const steps = data.recommendedNextWorkout.weightLiftingBlocks.map((x, index) => "Weight lifting block "+(index+1));

  if (hasHiit) {
    steps.push("HIIT");
  }

  steps.push("Core");

  const onStepComplete = () => {
    if (activeStep === steps.length - 1) {
      recordDoingWorkout({variables: { workoutId: data.recommendedNextWorkout.id, date }});
      return;
    }

    setActiveStep(activeStep + 1);
    props.onBlockComplete();
  }

  const getStepContent = (step) => {
    if (step < data.recommendedNextWorkout.weightLiftingBlocks.length) {
      return <WeightLiftingBlockStep exercises={data.recommendedNextWorkout.weightLiftingBlocks[step].exercises} onComplete={onStepComplete}/>
    } else if (step === data.recommendedNextWorkout.weightLiftingBlocks.length) {
      if (hasHiit) {
        return <HiitStep exercises={data.recommendedNextWorkout.hiitExercises} onComplete={onStepComplete}/>
      } else {
        return <CoreStep exercises={data.recommendedNextWorkout.coreExercises} onComplete={onStepComplete}/>
      }
    } else if (step === data.recommendedNextWorkout.weightLiftingBlocks.length + 1 && hasHiit) {
      return <CoreStep exercises={data.recommendedNextWorkout.coreExercises} onComplete={onStepComplete}/>
    }

    return "Unknown step";
  }

  const className = classes.root+" "+props.className;

  return (
    <div className={className}>
      <Stepper classes={{root: classes.stepper}} activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent classes={{root: classes.stepContent}}>
              <div className={classes.innerStepContent}>
                {getStepContent(index)}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
