import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sets: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24
  },
  verticalSets: {
    flexDirection: 'column',
    '& .MuiFormControlLabel-root': {
      marginBottom: 8
    }
  },
  formControlLabel: {
    color: '#26401B',
    marginRight: 0
  }
}));

export default function SetCheckboxes(props) {
  const classes = useStyles();
  const setDoneStatus = props.setDoneStatus;
  const [emittedCompleteEvent, setEmittedCompleteEvent] = React.useState(false);
  const onComplete = props.onComplete;

  React.useEffect(() => {
    const allSetsChecked = setDoneStatus.every((setDoneStatus) => setDoneStatus);

    if (allSetsChecked && !emittedCompleteEvent) {
      onComplete();
      setEmittedCompleteEvent(true);
    }
  }, [setDoneStatus, onComplete, emittedCompleteEvent]);

  let setsClassName = classes.sets;

  if (props.vertical) {
    setsClassName += " "+classes.verticalSets;
  }

  return (
    <div className={props.className}>
      <div className={setsClassName}>
        {props.setDoneStatus.map((setDoneStatus, index) => (
          <FormControlLabel
            key={index} 
            className={classes.formControlLabel}
            control={
              <Checkbox
                checked={setDoneStatus}
                onChange={(event) => props.onSetCheckboxChange(index, event)}
                color="primary"
              />
            }
            label={"Set "+(index+1)}
          />
        ))}
      </div>
    </div>
  );
}
