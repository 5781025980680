import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SetCheckboxes from './SetCheckboxes';
import ExerciseCard from './exercise-card';
import TimerBetweenSets from './TimerBetweenSets';

const useStyles = makeStyles((theme) => ({
  exerciseCards: {
    marginLeft: 32,
    marginRight: 32,
    display: 'flex',
    flexWrap: 'wrap',
  },
  exerciseCard: {
    width: '100%',
    marginTop: 8
  },
  threeSets: {
    marginLeft: 32,
    marginTop: 15,
    marginRight: 32,
  },
  timerBetweenSets: {
    margin: 'auto'
  }
}));

export default function WeightLiftingBlockStep(props) {
  const classes = useStyles();
  const [setDoneStatus, setSetDoneStatus] = React.useState([false, false, false]);
  const [showThreeSetsTimer, setShowThreeSetsTimer] = React.useState(false);
  const firstUncompleteSetIndex = setDoneStatus.findIndex((setDoneStatus) => !setDoneStatus);

  React.useEffect(() => {
    const noSetsChecked = setDoneStatus.every((setDoneStatus) => !setDoneStatus);
    const allSetsChecked = setDoneStatus.every((setDoneStatus) => setDoneStatus);
    setShowThreeSetsTimer(!(noSetsChecked || allSetsChecked));
  }, [setDoneStatus]);

  const onSetCheckboxChange = (index, event) => {
    setSetDoneStatus((setDoneStatus) => {
      return setDoneStatus.slice(0, index).concat([event.target.checked].concat(setDoneStatus.slice(index + 1)));
    });
  };

  return (
    <div>
      <div className={classes.exerciseCards}>
        {props.exercises.map((exercise, index) => (
          <ExerciseCard key={index} className={classes.exerciseCard} label={exercise}/>
        ))}
      </div>
      <SetCheckboxes
        className={classes.threeSets}
        setDoneStatus={setDoneStatus}
        onComplete={props.onComplete}
        onSetCheckboxChange={onSetCheckboxChange}
        />
      {showThreeSetsTimer &&
        <TimerBetweenSets
          className={classes.timerBetweenSets}
          setCompletedCount={firstUncompleteSetIndex}
          onTimerComplete={() => {}}
        />
      }
    </div>
  );
}