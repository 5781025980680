import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Button from '@material-ui/core/Button';
import waveUrl from './mixkit-sport-start-bleeps-918.wav';

export default function HiitTimer(props) {
  const audioRef = React.createRef();
  const [timerPlaying, setTimerPlaying] = React.useState(false);
  const [timerDuration, setTimerDuration] = React.useState(60);
  const [timerKey, setTimerKey] = React.useState(0);
  const [audioTimerState, setAudioTimerInState] = React.useState(null);
  const [playAudio, setPlayAudio] = React.useState(false);

  React.useEffect(() => {
    if (timerPlaying) {
      const timer = setTimeout(() => {
        setPlayAudio(true);
      }, (timerDuration - 3) * 1000);
      
      setAudioTimerInState(timer);
    }
  }, [timerPlaying, timerDuration]);

  React.useEffect(() => {
    if (audioTimerState) {
      return () => {
        clearTimeout(audioTimerState);
      };
    }
  }, [audioTimerState]);

  React.useEffect(() => {
    if (playAudio) {
      audioRef.current.play();
      setPlayAudio(false);
    }
  }, [playAudio, audioRef]);

  const onTimerComplete = () => {
    setTimerPlaying(false);

    if (timerDuration === 60) {
      setTimerDuration(45);
      setTimerKey(prevTimerKey => prevTimerKey + 1);
    } else if (timerDuration === 45) {
      setTimerDuration(30);
      setTimerKey(prevTimerKey => prevTimerKey + 1);
    } else if (timerDuration === 30) {
      if (props.exerciseCount === 3) {
        props.onComplete();
      } else {
        setTimerDuration(15);
        setTimerKey(prevTimerKey => prevTimerKey + 1);
      }
    } else if (timerDuration === 15) {
      props.onComplete();
    }
  }

  const onButtonClick = () => {
    setTimerPlaying(true);
  }

  return (
    <div>
      <CountdownCircleTimer
        key={timerKey}
        isPlaying={timerPlaying}
        duration={timerDuration}
        onComplete={onTimerComplete}
        size={152}
        colors='#795841'
        trailColor='#F2EBE4'
      >
        {({ remainingTime }) => remainingTime}
      </CountdownCircleTimer>
      <Button onClick={onButtonClick}>
        Start timer
      </Button>
      <audio ref={audioRef}>
        <source src={waveUrl} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}