import React from 'react';
import Button from '@material-ui/core/Button';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { makeStyles } from '@material-ui/core/styles';
import waveUrl from './mixkit-sport-start-bleeps-918.wav';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: -8,
    marginTop: 8
  }
}));

export default function CoreTimer(props) {
  const audioRef = React.createRef();
  const classes = useStyles();
  const [timerPlaying, setTimerPlaying] = React.useState(false);
  const [timerKey, setTimerKey] = React.useState(0);
  const [timerPlayedCount, setTimerPlayedCount] = React.useState(0);
  const [audioTimerState, setAudioTimerInState] = React.useState(null);
  const [playAudio, setPlayAudio] = React.useState(false);

  React.useEffect(() => {
    if (timerPlaying) {
      const timer = setTimeout(() => {
        setPlayAudio(true);
      }, 27000);
      
      setAudioTimerInState(timer);
    }
  }, [timerPlaying]);

  React.useEffect(() => {
    if (audioTimerState) {
      return () => {
        clearTimeout(audioTimerState);
      };
    }
  }, [audioTimerState]);

  React.useEffect(() => {
    if (playAudio) {
      audioRef.current.play();
      setPlayAudio(false);
    }
  }, [playAudio, audioRef]);

  const onTimerComplete = () => {
    setTimerPlaying(false);
    setTimerKey(prevTimerKey => prevTimerKey + 1);

    if (timerPlayedCount === 1) {
      props.onComplete();
    }

    setTimerPlayedCount(prevTimerPlayedCount => prevTimerPlayedCount + 1);
  };

  const onButtonClick = () => {
    setTimerPlaying(true);
  };

  return (
    <div>
      <CountdownCircleTimer
        key={timerKey}
        isPlaying={timerPlaying}
        duration={30}
        onComplete={onTimerComplete}
        size={152}
        colors='#795841'
        trailColor='#F2EBE4'
      >
        {({ remainingTime }) => remainingTime}
      </CountdownCircleTimer>
      <Button className={classes.button} onClick={onButtonClick}>
        Start timer
      </Button>
      <audio ref={audioRef}>
        <source src={waveUrl} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}